import { Box } from '@mui/material';

export const Trustpilot = () => {
  return (
    <Box sx={{ mt: 10 }}>
      <div
        class="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="660843d3baae927d36216421"
        data-style-height="52px"
        data-style-width="100%"
      >
        <a
          href="https://uk.trustpilot.com/review/drvella.co.uk"
          target="_blank"
          rel="noreferrer"
        >
          Review us on Trustpilot
        </a>
      </div>
    </Box>
  );
};
