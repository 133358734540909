import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Countdown } from './Countdown';
import { REVIEW_URL } from '../utils/constants';

const renderLink = () => <Link href={REVIEW_URL}>Trustpilot</Link>;
const countDownText = <>Redirecting to {renderLink()} in </>;

const timerIsUpText = <>Navigate manually to {renderLink()}.</>;
export const Popup = ({
  open,
  handleClose,
  isLoading,
  dialogText,
  setDialogText,
}) => {
  const [isClicked, setIsClick] = useState(false);
  function copyText() {
    setIsClick(true);
    const reviewContent = document.getElementById('review-content');
    if (reviewContent) {
      const textToCopy = reviewContent.innerText || reviewContent.textContent;

      // Use the Clipboard API to copy text to the clipboard
      navigator.clipboard
        .writeText(textToCopy)
        .then(function () {
          setDialogText(
            <>
              You will be automatically redirected to {renderLink()}.
              <br />
              <br />
              <Countdown
                link={REVIEW_URL}
                countDownText={countDownText}
                timerIsUpText={timerIsUpText}
              />
            </>
          );
        })
        .catch(function (err) {
          console.error('Unable to copy text: ', err);
        });
    } else {
      console.error('Element with id "review-content" not found.');
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{`${isLoading ? 'Generating Review' : ''}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isLoading ? (
            <>
              <Skeleton width={'100%'} variant={'text'}></Skeleton>
              <Skeleton width={'100%'} variant={'text'}></Skeleton>
              <Skeleton width={'100%'} variant={'text'}></Skeleton>
              <Skeleton width={'100%'} variant={'text'}></Skeleton>
              <Skeleton width={'100%'} variant={'text'}></Skeleton>
            </>
          ) : (
            <Typography id="review-content">{dialogText}</Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'text'}
          disabled={isLoading || isClicked}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant={'contained'}
          disabled={isLoading || isClicked}
          onClick={copyText}
          autoFocus
        >
          Copy Review
        </Button>
      </DialogActions>
    </Dialog>
  );
};
