import React, { useState, useEffect } from 'react';

export const Countdown = ({ countDownText, timerIsUpText, link }) => {
  const [timeLeft, setTimeLeft] = useState(9);

  useEffect(() => {
    // redirect to link prop when counter stops
    if (timeLeft === 0) {
      window.location.href = link;
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // Add timeLeft as a dependency to re-run the effect
    // when we update it
  }, [timeLeft, link]);

  return timeLeft > 0 ? (
    <span>
      {countDownText} {timeLeft}.
    </span>
  ) : (
    <span>{timerIsUpText}</span>
  );
};
