import { Box, Rating, Typography } from '@mui/material';
import React from 'react';
import { RATING } from '../utils/constants';
import { Back } from './back';
import { ProgressBar } from './ProgressBar';

export const StarRating = ({ formData, handleChange, step, setStep }) => {
  function valueText(value) {
    return `${RATING[value - 1] || 'Please select your rating 1 to 5'}`;
  }

  const sections = [
    {
      id: 'consultationSatisfaction',
      label: 'Consultation Satisfaction',
      step: 2,
    },
    { id: 'comfortPreparedness', label: 'Comfort and Preparedness', step: 3 },
    { id: 'professionalismCare', label: 'Professionalism and Care', step: 4 },
    { id: 'painDiscomfort', label: 'Pain and Discomfort', step: 5 },
    { id: 'communication', label: 'Communication', step: 6 },
    { id: 'satisfactionResults', label: 'Satisfaction with Results', step: 7 },
    {
      id: 'recommendationLikelihood',
      label: 'Recommendation Likelihood',
      step: 8,
    },
  ];

  return (
    <Box sx={{ display: 'block' }}>
      <Back step={step} setStep={setStep} />
      {sections
        .filter((section) => section.step === step)
        .map((section) => (
          <Box
            key={section.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              alignItems: 'center',
              mt: 2,
              minHeight: '200px',
            }}
          >
            <Typography
              id={`${section.id}Label`}
              variant={'h5'}
              sx={{ textTransform: 'uppercase' }}
            >
              {section.label}
            </Typography>
            <Box key={section.id} sx={{ mt: 2 }}>
              <Rating
                precision={1}
                value={formData[section.id]}
                onChange={handleChange}
                name={section.id}
                getLabelText={valueText}
                max={5}
                size={'large'}
              />
              <Box sx={{ fontWeight: 300, fontSize: 10, textAlign: 'center' }}>
                {valueText(formData[section.id])}
              </Box>
            </Box>
          </Box>
        ))}

      <ProgressBar step={step} />
    </Box>
  );
};
