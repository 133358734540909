import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { Back } from './back';
import { ProgressBar } from './ProgressBar';
import { Submit } from './Submit';

export const Comments = ({ formData, handleTextBoxChange, step, setStep }) => {
  return (
    <Box sx={{ display: 'block' }}>
      <Back step={step} setStep={setStep} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          mt: 2,
          minHeight: '200px',
        }}
      >
        <Typography variant="h5" gutterBottom>
          ADDITIONAL COMMENTS
        </Typography>
        <TextField
          fullWidth
          label="Additional Comments"
          name="additionalComments"
          value={formData.additionalComments}
          onChange={handleTextBoxChange}
          multiline
          rows={4}
          margin="normal"
          variant="outlined"
        />
      </Box>
      <ProgressBar step={step} />
      <Submit />
    </Box>
  );
};
