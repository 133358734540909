import { AppBar, Link, Toolbar } from '@mui/material';
import React from 'react';
import { DR_FRANCESCA_VELLA } from '../utils/constants';

export const TopMenu = () => {
  return (
    <AppBar
      position="static"
      sx={{ py: 2, justifyContent: 'center', alignItems: 'center' }}
    >
      <Toolbar variant={'dense'} disableGutters sx={{ minHeight: '0' }}>
        <Link
          sx={{ maxWidth: '90%', m: 'auto' }}
          href={'https://drvella.co.uk'}
        >
          <img
            src={'/logo-banner.jpg'}
            alt={DR_FRANCESCA_VELLA}
            style={{
              maxHeight: 70,
              maxWidth: '100%',
              display: 'flex',
            }}
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
