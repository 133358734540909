import { Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

export const Back = ({ step, setStep }) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={() => setStep(step - 1)}
      sx={{ mr: 'auto', opacity: 0.3 }}
    >
      <ArrowBack />
    </Button>
  );
};
