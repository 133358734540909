import { Box, Container, createTheme, ThemeProvider } from '@mui/material';
import { TopMenu } from '../components/TopMenu';
import { Popup } from '../components/Popup';
import React, { useState } from 'react';
import { LOADING_TEXT } from '../utils/constants';
import { Step1 } from '../components/Step1';
import { StarRating } from '../components/StarRating';
import { Comments } from '../components/comments';

export const Wizard = () => {
  const a = {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
    '&:hover': {
      backgroundColor: '#757575',
      borderColor: 'black',
    },
  };
  const theme = createTheme({
    // set font to PT Serif
    typography: {
      fontFamily: ['PT Serif, serif'].join(','),
    },
    palette: {
      primary: {
        main: '#f6cf00',
        secondary: '#fff',
      },
    },
    // MuiButton secondary outline black
    components: {
      MuiButton: {
        styleOverrides: {
          outlinedSecondary: a,
          textSecondary: a,
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#212121',
            color: 'white',
            alignItems: 'center',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          handwriting: {
            fontFamily: 'Mr Dafoe',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(246,207,0,0.21)',
            color: '#212121',
          },
          icon: {
            color: '#212121 !important',
          },
        },
      },

      MuiSlider: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
    },
  });

  const [formData, setFormData] = useState({
    consultationSatisfaction: 0,
    comfortPreparedness: 0,
    professionalismCare: 0,
    painDiscomfort: 0,
    communication: 0,
    satisfactionResults: 0,
    recommendationLikelihood: 0,
    additionalComments: '',
  });
  const [dialogText, setDialogText] = useState('');

  const [step, setStep] = React.useState(1);
  const [open, setOpen] = useState(false);

  const isLoading = dialogText === LOADING_TEXT;

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 setStep={setStep} />;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return (
          <StarRating
            formData={formData}
            handleChange={handleChange}
            step={step}
            setStep={setStep}
          />
        );
      case 9:
        return (
          <Comments
            setStep={setStep}
            step={step}
            formData={formData}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
      default:
        return <Step1 setStep={setStep} />;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
    setStep(step + 1);
  };
  const handleTextBoxChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const fetchData = async () => {
    try {
      const response = await fetch('/api/hello', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('There was a problem fetching data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDialogText(LOADING_TEXT);
    handleClickOpen();
    const data = await fetchData();
    setDialogText(data.message);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <TopMenu />
      <Container maxWidth="sm" sx={{ pt: 2 }}>
        <Box component="form" onSubmit={handleSubmit}>
          {renderStep()}
        </Box>
        <Popup
          isLoading={isLoading}
          dialogText={dialogText}
          setDialogText={setDialogText}
          handleClose={handleClose}
          open={open}
        />
      </Container>
    </ThemeProvider>
  );
};
