import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Wizard } from './pages/Wizard';
import { GoToTrustpilot } from './pages/GoToTrustpilot';

const Main = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <GoToTrustpilot />,
    },
    { path: '/wizard', element: <Wizard /> },
  ]);
  return <RouterProvider router={router} />;
};

export default Main;
