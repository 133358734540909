import { Box, Button, Paper, Typography } from '@mui/material';
import { REVIEW_URL } from '../utils/constants';
import { Trustpilot } from './Trustpilot';

export const Step1 = ({ setStep }) => {
  return (
    <Box>
      <Paper
        sx={{
          textAlign: 'center',
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 4,
        }}
        square
      >
        <Typography variant="">Need help preparing your review?</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setStep(2)}
          >
            yes
          </Button>
          <Button
            variant="text"
            color="secondary"
            size="large"
            onClick={() => (window.location.href = REVIEW_URL)}
          >
            no, take me to Trustpilot
          </Button>
        </Box>
      </Paper>
      <Trustpilot />
    </Box>
  );
};
