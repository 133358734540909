import { Button } from '@mui/material';
import React from 'react';

export const Submit = () => {
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      sx={{ mt: 3 }}
      fullWidth
    >
      Submit
    </Button>
  );
};
